import React, { useState } from "react";
import "./login.css";
import "react-phone-number-input/style.css";
import ButtonComponent from "../../components/button/index.jsx";
import AlertSmall from "../../components/alert/alertSmall.jsx";
import { app } from "../../components/firebase/firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import "../../components/input/inputComponent.css";
import InputComponent from "../../components/input/index.jsx";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const auth = getAuth(app);

  const pathApi = "https://us-central1-loukaaa.cloudfunctions.net/api";

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("url");

  const handleSignUp = async () => {
    setLoading(true);
    setError("");

    try {
      // Création de l'utilisateur avec email et mot de passe
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      try {
        // Mise à jour du profil utilisateur avec prénom et nom
        await updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        });
      } catch (profileError) {
        console.error(
          "Erreur lors de la mise à jour du profil :",
          profileError
        );
        setError("Une erreur est survenue lors de la mise à jour du profil.");
        setLoading(false);
        return;
      }

      try {
        // Enregistrement des données de l'utilisateur dans Firestore
        await app.firestore().collection("users").doc(user.uid).set({
          email: user.email,
          firstName: firstName,
          lastName: lastName,
          createdAt: new Date(),
        });
      } catch (firestoreError) {
        console.error(
          "Erreur lors de l'enregistrement dans Firestore :",
          firestoreError
        );
        setError(
          "Une erreur est survenue lors de l'enregistrement des données."
        );
        setLoading(false);
        return;
      }

      try {
        // Récupération du token d'ID et envoi au backend pour générer un cookie
        const idToken = await user.getIdToken();
        const response = await fetch(pathApi + "/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken }),
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Erreur lors de la connexion avec le backend.");
        }

        const data = await response.json();
        Cookies.set("session", data.sessionCookie, {
          expires: 7,
          domain: ".loukaaa.com",
        });
      } catch (backendError) {
        console.error(
          "Erreur lors de la connexion avec le backend :",
          backendError
        );
        setError("Impossible de se connecter au serveur. Veuillez réessayer.");
        setLoading(false);
        return;
      }

      // Si tout est bon
      console.log("User successfully logged in");
      setLoading(false);
      redirectUrl && (window.location.href = redirectUrl);
    } catch (error) {
      console.error("Erreur lors de l'inscription :", error);
      setError("Échec de l'inscription. Vérifiez vos informations.");
      setLoading(false);
    }
  };

  return (
    <div className="Signup Login" id="Signup">
      <div className="left"></div>
      <div className="right">
        <div className="header">
          <h3>
            Bienvenue <span>👋</span>
          </h3>
          <p>
            Inscrivez-vous pour profiter de nos services et offres exclusives.
          </p>
        </div>
        <div className="form">
          {error !== "" && (
            <AlertSmall type="error" style={{ marginBottom: 20 }}>
              {error}
            </AlertSmall>
          )}
          <div className="two-input">
            <InputComponent
              type="text"
              placeholder="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              width="100%"
            />
            <InputComponent
              type="text"
              placeholder="Nom"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              width="100%"
            />
          </div>
          <InputComponent
            type="text"
            placeholder="Adresse email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            width="100%"
          />
          <InputComponent
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            width="100%"
          />
          <div className="checkbox-wrapper">
            <input className="inp-cbx" id="morning" type="checkbox" />
            <label className="cbx" htmlFor="morning">
              <span>
                <svg width="12px" height="10px">
                  <use xlinkHref="#check-4"></use>
                </svg>
              </span>
              <span>
                J'accepte les{" "}
                <a href="https://loukaaa.com/cgu">conditions d'utilisation</a>{" "}
                et la{" "}
                <a href="https://loukaaa.com/cookie">
                  politique de confidentialité
                </a>
                .
              </span>
            </label>
            <svg className="inline-svg">
              <symbol id="check-4" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </symbol>
            </svg>
          </div>
        </div>
        <div className="footer">
          <div className="button-container">
            <ButtonComponent
              text="Continuer"
              onClick={() => handleSignUp()}
              icon={true}
              loading={loading}
              style={{ width: "100%" }}
              iconLeft={true}
            />
          </div>
          <span>
            Vous avez un compte ? <Link to="/signin">Se connecter</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Signup;
