import React, { useState } from "react";
import "./login.css";
import "react-phone-number-input/style.css";
import ButtonComponent from "../../components/button/index.jsx";
import AlertSmall from "../../components/alert/alertSmall.jsx";
import { app } from "../../components/firebase/firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "../../components/input/inputComponent.css";
import InputComponent from "../../components/input/index.jsx";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const auth = getAuth(app);

  const pathApi = "https://us-central1-loukaaa.cloudfunctions.net/api";

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("url");

  const handleSignIn = async () => {
    setLoading(true);
    setError("");

    try {
      // Connexion de l'utilisateur avec email et mot de passe
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      try {
        // Récupération du token d'ID et envoi au backend pour générer un cookie
        const idToken = await user.getIdToken();
        const response = await fetch(pathApi + "/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken }),
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Erreur lors de la connexion avec le backend.");
        }

        const data = await response.json();
        Cookies.set("session", data.sessionCookie, {
          expires: 7,
          domain: ".loukaaa.com",
        });
      } catch (backendError) {
        console.error(
          "Erreur lors de la connexion avec le backend :",
          backendError
        );
        setError("Impossible de se connecter au serveur. Veuillez réessayer.");
        setLoading(false);
        return;
      }

      // Si tout est bon
      console.log("User successfully logged in");
      setLoading(false);
      redirectUrl && (window.location.href = redirectUrl);
    } catch (error) {
      console.error("Erreur lors de la connexion :", error);
      setError("Email ou mot de passe incorrect");
      setLoading(false);
    }
  };

  return (
    <div className="Signin Login" id="Signin">
      <div className="left"></div>
      <div className="right">
        <div className="header">
          <h3>
            De retour <span>👋</span>
          </h3>
          <p>
            Connectez-vous pour accéder à votre compte et continuer à utiliser
            le service.
          </p>
        </div>
        <div className="form">
          {error !== "" && (
            <AlertSmall type="error" style={{ marginBottom: 20 }}>
              {error}
            </AlertSmall>
          )}
          <InputComponent
            type="text"
            placeholder="Adresse email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            width="100%"
          />
          <InputComponent
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            width="100%"
          />
        </div>
        <div className="footer">
          <div className="button-container">
            <ButtonComponent
              text="Connexion"
              onClick={() => handleSignIn()}
              icon={true}
              loading={loading}
              style={{ width: "100%" }}
              iconLeft={true}
            />
          </div>
          <span>
            Vous n'avez pas de compte ? <Link to="/signup">S'inscrire</Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Signin;
